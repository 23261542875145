import React, { useState } from 'react'

import Link from 'next/link'

// Custom Components
import JoinWaitListBtn from './base/JoinWaitListBtn'
import JoinWaitlist from './modals/JoinWaitlist'
import styles from './GetStarted.module.css'

const GetStarted = ({ blueVariant }) => {
    // Join Waitlist Modal
    const [isModalOpen, setIsModalOpen] = useState(false)

    const openModal = () => {
        setIsModalOpen(true)
    }
    const closeModal = () => {
        setIsModalOpen(false)
    }
    return (
        <>
            <div
                className={`${styles.getStarted} ${
                    blueVariant ? styles.blueVariant : ''
                }`}
                itemScope
                itemType="https://schema.org/WebPage"
            >
                <p itemProp="headline">
                    Add AI &amp; Machine Learning to your business in minutes.
                    Ready to dive in?&nbsp;
                    <span
                        onClick={openModal}
                        className={styles.startForFreeToday}
                    >
                        Start for free today.
                    </span>
                </p>
                <div className={styles.bottomSection} itemProp="description">
                    {/* Joing early wait list */}
                    <JoinWaitListBtn
                        size="lg"
                        variant={blueVariant ? 'default' : 'primary'}
                    />

                    <div>
                        <Link href="/contact">
                            <a className="inline-flex">
                                Request a consultation
                                <i className="icon-arrow-right-circle mobile:top-0" />
                            </a>
                        </Link>
                    </div>
                </div>
                {/* Join Waitlist Modal */}
                <JoinWaitlist
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                />
            </div>
        </>
    )
}
export default GetStarted
