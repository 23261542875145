import React from 'react'
import ReactPlayer from 'react-player'
import { useMediaQuery } from 'react-responsive'
import Link from 'next/link'
import Image from 'next/image'

import { ClientOnly } from 'react-client-only'

// Custom Components
import JoinWaitListBtn from '../base/JoinWaitListBtn'

const Banner = (props) => {
    // For desktop only
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1024px)',
    })

    return (
        <>
            <div
                className="max-w-[1320px] lg:max-w-[1130px] md:max-w-[980px] tablet:w-full mx-auto banner-component"
                role="banner"
                itemScope
                itemType="http://schema.org/WPHeader"
            >
                <div className="flex items-center w-full bg-interface-100 rounded-xl ipad:rounded-none ipad:flex-col ipad:pt-9 ipad:px-12 tablet:px-4">
                    <div className="w-full pl-24 lg:pl-8 tablet:px-4">
                        <div>
                            <h1
                                className="inline-block mb-8 text-4xl text-primary-900 md:text-3xl ipad:text-center ipad:text-4xl tablet:text-3xl"
                                itemProp="headline"
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: props.title,
                                    }}
                                ></span>
                                <span
                                    className="pl-2 text-primary-500"
                                    itemProp="name"
                                >
                                    Smart Answers.
                                </span>
                            </h1>
                        </div>
                        <p
                            className="mb-8 text-xl font-normal text-interface-900 md:text-lg ipad:text-center ipad:text-xl"
                            itemProp="description"
                        >
                            {props.description}
                        </p>
                        <div className="flex items-center ipad:items-center ipad:justify-center tablet:flex-col">
                            {/* Joing early wait list */}
                            <JoinWaitListBtn size="lg" />
                            <div>
                                <Link href="/contact">
                                    <a className="inline-flex justify-center ml-8 text-xl font-medium tablet:mx-auto tablet:relative tablet:left-1 tablet:mt-7 tablet:block">
                                        Request a consultation
                                        <i className="relative pl-2 icon-arrow-right-circle top-1 tablet:top-[2px]" />
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className=" flex-shrink-0 w-full ipad:mx-auto ipad:max-w-[600px]  ml-auto relative  max-w-[640px] lg:max-w-[550px] md:max-w-[445px] md:p-0 md:pt-9 lg:p-8 lg:pb-0">
                        <div>
                            <div className="relative pt-[71%] player-wrapper mt-6 tablet:mt-0">
                                {props.coverImage && (
                                    <div className="absolute top-0 left-0 w-full h-full">
                                        <Image
                                            src={props.coverImage}
                                            alt={props.title}
                                            layout="responsive"
                                            width={640}
                                            height={450}
                                            itemProp="image"
                                        />
                                    </div>
                                )}
                                <ClientOnly>
                                    {isDesktopOrLaptop && (
                                        <ReactPlayer
                                            url={props.videoUrl}
                                            className="absolute top-0 left-0 react-player bg-interface-100"
                                            playing
                                            muted
                                            loop
                                            width="100%"
                                            height="100%"
                                        />
                                    )}
                                </ClientOnly>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Banner
