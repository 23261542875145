import { Card, CardBody } from '@windmill/react-ui'

import Image from 'next/image'

const FeaturesCard = ({ featuresDetail }) => {
    return (
        <>
            <Card className="bg-secondary text-common-white">
                <CardBody className="p-6">
                    <div className="flex">
                        {/* Icon */}
                        <div className="flex-shrink-0 ">
                            <div className="w-16 mr-5 tablet:w-12">
                                <Image
                                    src={featuresDetail.img}
                                    alt={featuresDetail.title}
                                    layout="responsive"
                                    width={64}
                                    height={64}
                                />
                            </div>
                        </div>
                        {/* content */}
                        <div>
                            {/* title */}
                            <h3 className="mb-2 font-semibold text-common-white">
                                {featuresDetail.title}
                            </h3>
                            {/* description */}
                            <p className="mb-0 text-common-white">
                                {featuresDetail.description}
                            </p>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}
export default FeaturesCard
