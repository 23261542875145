import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import Image from 'next/image'

const Testimonial = () => {
    return (
        <>
            <div itemScope itemType="https://schema.org/WebPage">
                <Carousel
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                >
                    <div>
                        <div className="flex w-full tablet:flex-col-reverse">
                            <div className="flex items-end flex-shrink-0 mr-4 tablet:mt-5 tablet:pl-14">
                                <div className="flex items-center ">
                                    <span className="w-[56px] h-[56px] rounded-full mr-3 overflow-hidden">
                                        <Image
                                            src="/testimonial/philip-may.Jpeg"
                                            alt="Philip May"
                                            layout="responsive"
                                            width={48}
                                            height={48}
                                            itemProp="image"
                                        />
                                    </span>
                                    <h3
                                        className="mb-0 text-left text-secondary"
                                        itemProp="author"
                                    >
                                        Philip May
                                        <span className="block text-sm font-normal text-interface-700">
                                            Co-Founder, The BarCode
                                        </span>
                                    </h3>
                                </div>
                            </div>
                            <div className="relative pl-16 tablet:pl-0">
                                <span className="absolute left-0 -top-2 w-9 tablet:-top-0 tablet:w-7">
                                    <Image
                                        src="/svg/quotes.svg"
                                        alt="Quotes"
                                        layout="responsive"
                                        width={40}
                                        height={40}
                                    />
                                </span>
                                <p
                                    className="w-full mb-0 text-lg text-left text-interface-900 tablet:pl-11"
                                    itemProp="description"
                                >
                                    I cannot recommend Smart Answers highly
                                    enough. Smart Answers were able to take on
                                    board our vision and got it straight away.
                                    They managed our expectations and helped
                                    prioritize our goals for both the MVP and
                                    later!
                                </p>
                            </div>
                        </div>
                    </div>
                </Carousel>
            </div>
        </>
    )
}
export default Testimonial
