// Next js Components
import Head from 'next/head'
import Link from 'next/link'
import Image from 'next/image'

import Vimeo from '@u-wave/react-vimeo'

// Custom Components
import Banner from '../components/landing/Banner'
import CategoryCard from '../components/CategoryCard'
import FeaturesCard from '../components/FeaturesCard'
import Testimonial from '../components/Testimonial'
import GetStarted from '../components/GetStarted'

const metaImage =
    process.env.NEXT_PUBLIC_APP_URL + '/og-images/og-images_home-page.jpg'

const metaTitle =
    'AI & Machine Learning Solutions | ' + process.env.NEXT_PUBLIC_APP_NAME

const metaDescription =
    'Our growing library of AI & Machine Learning models provide a plug &amp; play experience, generating actionable insights that can help your business thrive in 2022.'

// Categories
const categories = [
    {
        id: 1,
        category: 'Ecommerce',
        description:
            'Improve sales by offering personalized recommendations, related products, targeted deals, and more.',
        img: '/landing/icons/ecommerce.svg',
        route: '/machine-learning-solutions/ecommerce',
    },
    {
        id: 2,
        category: 'Social Media & Communication',
        description:
            'Get insight on public perception across your social channels with Sentiment Analysis & Keyword Extraction.',
        img: '/landing/icons/social-media.svg',
        route: '/machine-learning-solutions/social-media-communication',
    },
    {
        id: 3,
        category: 'Digital Marketing',
        description:
            'Find out which of your digital marketing campaigns are over or under performing and why.',
        img: '/landing/icons/digital-marketing.svg',
        route: '/machine-learning-solutions/digital-marketing',
    },
    {
        id: 4,
        category: 'Enterprise',
        description:
            'Generate actionable insight across your enterprise from Sales to HR to Finance and beyond.',
        img: '/landing/icons/enterprise.svg',
        route: '/machine-learning-solutions/enterprise',
    },
    {
        id: 5,
        category: 'Real Estate',
        description:
            'Use AI to evaluate the price of properties in relation to similar offerings on the market, and beyond.',
        img: '/landing/icons/real-estate.svg',
        route: '/machine-learning-solutions/real-estate',
    },
    {
        id: 6,
        category: 'Restaurants',
        description:
            'Understand which customers, items, time of day and employees are generating the most value.',
        img: '/landing/icons/resturant.svg',
        route: '/machine-learning-solutions/restaurant',
    },
]

// App features
const features = [
    {
        id: 1,
        title: 'Fast',
        description:
            'Our advanced technology infrastructure provides extremely short response times.',
        img: '/landing/icons/fast.svg',
    },
    {
        id: 2,
        title: 'Scalable',
        description:
            'We can handle high volume requests that scale with your needs.',
        img: '/landing/icons/scalable.svg',
    },
    {
        id: 3,
        title: 'Easy to use',
        description:
            'Let us handle the Machine Learning so you can focus on your business.',
        img: '/landing/icons/easy-to-use.svg',
    },
]

const Index = () => {
    return (
        <>
            <Head>
                {process.browser ? (
                    <title>{metaTitle}</title>
                ) : (
                    <title
                        dangerouslySetInnerHTML={{ __html: metaTitle }}
                    ></title>
                )}
                <meta name="description" content={metaDescription} />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:image" content={metaImage} />
                <meta
                    property="og:url"
                    content={`${process.env.NEXT_PUBLIC_APP_URL}`}
                />
                <meta name="twitter:title" content={metaTitle} />
                <meta name="twitter:description" content={metaDescription} />
                <meta name="twitter:image" content={metaImage} />
            </Head>
            <div>
                {/* Banner */}
                <Banner
                    title="One Platform.<br/>
                            Multiple Smart Solutions.
                            Limitless"
                    description="The Smart Answers Platform offers state-of-the-art AI &amp; Machine Learning solutions for every business need."
                    videoUrl="/video/landing-video.mp4"
                    coverImage="/video/cover/landing.png"
                />
                <div className="container">
                    {/* =========== First Blade below the fold =========== */}
                    <div
                        className="pb-10 section"
                        itemScope
                        itemType="https://schema.org/WebPage"
                    >
                        <div>
                            <h2 className="heading" itemProp="headline">
                                Generate actionable business intelligence from
                                your data
                            </h2>
                            <p className="description" itemProp="description">
                                Most businesses have a plethora of data that
                                they collect on an ongoing basis. Where they
                                fall short is leveraging the data to make smart
                                decisions. With our growing library of AI &amp;
                                Machine Learning models, you can find Smart
                                Answers for your business cases without any
                                prior data science expertise.
                            </p>
                        </div>
                        {/* Category Card */}
                        <div className="grid grid-cols-3 gap-8 ipad:grid-cols-2 tablet:grid-cols-1 tablet:gap-5">
                            {categories.map((category) => (
                                <CategoryCard
                                    key={category.id}
                                    categoryDetails={category}
                                />
                            ))}
                        </div>
                        {/* Contact Us */}
                        <div>
                            <p className="mt-10 text-xl font-bold text-center">
                                Looking for a Machine Learning solution custom
                                built for your needs?
                                <span>
                                    <Link href="/contact">
                                        <a className="ml-2">Contact us</a>
                                    </Link>
                                </span>
                            </p>
                        </div>
                    </div>
                    {/* separator */}
                    <div className="separator"></div>
                    <div
                        className="pb-0 section"
                        itemScope
                        itemType="https://schema.org/WebPage"
                    >
                        <div>
                            <h2 className="heading" itemProp="headline">
                                See what Smart Answers can do for you
                            </h2>
                            <div
                                className="max-w-[780px] mx-auto"
                                itemProp="description"
                            >
                                <p className="description">
                                    Smart Answers is an easy-to-use Machine
                                    Learning & A.I. platform that does the heavy
                                    lifting for you, so you can focus on the
                                    business.
                                </p>
                            </div>
                            {/* Video */}
                            <div className="max-w-[780px] mx-auto">
                                <div className="relative pb-[56%] h-0 overflow-hidden max-w-full bg-interface-200 drop-shadow-xl">
                                    <Vimeo
                                        video="681208636"
                                        showTitle={false}
                                        showByline={false}
                                        showPortrait={false}
                                        className="intro-video"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* =========== Second Blade =========== */}
                    <div
                        className="section"
                        itemScope
                        itemType="https://schema.org/WebPage"
                    >
                        <div>
                            <h2 className="heading" itemProp="headline">
                                Become a data-driven organization by generating
                                insights that help you make smart decisions
                            </h2>
                            <div
                                className="max-w-[780px] mx-auto"
                                itemProp="description"
                            >
                                <p className="description">
                                    Improve transparency and accountability
                                    while embracing continuous improvement by
                                    adopting a data-driven philosophy towards
                                    making business decisions.
                                </p>
                            </div>
                            {/* App Features */}
                            <div className="relative tablet:p-5 tablet:bg-primary-500 tablet:rounded-xl">
                                <div className="w-[1000px] tablet:w-full tablet:hidden tablet:mx-auto ipad:w-[600px] ml-auto bg-primary-500 rounded-xl absolute right-0 h-full"></div>
                                <div className="flex tablet:flex-col-reverse">
                                    <div className="relative flex items-center justify-start w-full pr-7 tablet:px-0">
                                        <div>
                                            {features.map((feature) => (
                                                <div
                                                    key={feature.id}
                                                    className="my-4 tablet:last-of-type:mb-0"
                                                >
                                                    <FeaturesCard
                                                        featuresDetail={feature}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {/* App Screenshot */}
                                    <div className="w-[609px] ipad:w-[300px] tablet:w-full tablet:shadow-md tablet:mt-0 max-w-[609px] ml-auto flex-shrink-0 mt-7 mr-7  shadow rounded-tl-xl rounded-tr-xl overflow-hidden">
                                        <Image
                                            src="/landing/app-screenshot.jpg"
                                            alt="Similar product app-screenshot"
                                            layout="responsive"
                                            width={609}
                                            height={564}
                                            itemProp="image"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* separator */}
                    <div className="separator"></div>
                    {/* =========== Testimonial Blade =========== */}
                    <div className="pb-16 section lg:pb-10">
                        <Testimonial />
                    </div>
                    {/* =========== Get Started Blade =========== */}
                    <div className="pt-0 section">
                        <GetStarted />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index
