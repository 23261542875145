import { Card, CardBody } from '@windmill/react-ui'

import Link from 'next/link'

import Image from 'next/image'

const CategoryCard = ({ categoryDetails }) => {
    return (
        <>
            <Card
                className="relative text-left base-card"
                itemScope
                itemType="https://schema.org/ListItem"
            >
                <CardBody className="card-body">
                    <span className="block w-12 image">
                        <Image
                            src={categoryDetails.img}
                            alt={categoryDetails.category}
                            layout="responsive"
                            width={48}
                            height={48}
                            itemProp="image"
                        />
                    </span>
                    <div>
                        <h3 className="title" itemProp="title">
                            {categoryDetails.category}
                        </h3>
                        <p className="detail-content">
                            {categoryDetails.description}
                        </p>
                        <div>
                            <span className="font-medium text-primary-500">
                                Learn more
                            </span>
                            <Link href={categoryDetails.route}>
                                <a className="absolute top-0 left-0 w-full h-full text-base font-semibold"></a>
                            </Link>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}
export default CategoryCard
